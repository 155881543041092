export const CONSTANT = Object.freeze({
  EMAIL: "Email",
  DASHBOARD: "Dashboard",
  SUBMIT: "Submit",
  SUCCESS: "Success",
  ERROR: "Error",
  SWW: "Something went wrong.",
  SIGNOUT: "Sign Out",
  LOGIN: "Login",
  SIGNUP: "Sign up",
  SIGNIN: "Sign in",
  USERNAME: "Username",
  PASSWORD: "Password",
  PASSWORDMINLEN: 8,
  CONFIRMPASSWORD: "Confirm Password",
  FIRSTNAME: "First Name",
  LASTNAME: "Last Name",
  PHONENUMBER: "Phone Number",
  EMAILADDRESS: "Email Address",
  FORGOTPASSWORD: "Forgot Password",
  TERMS: "Terms of service",
  PRIVACY: "Privacy policy",
  GETSTARTED: "Get Started",
  CREATE_ACC: "Create account",
  PAST_TAX_FILE: "Past Tax Filings",
  OVERVIEW: "Overview",
  SEEALL: "See All",
  PERSONAL_TAX: "Personal Taxs",
  LIMITED_COMPANY: "Limited Company",
  PARTNERSHIP_TAX: "Partnership Tax",
  INSIGHTS_GUIDES: "Insights & Guides",
  SETTINGS: "Settings",
  BUSINESSES: "Businesses",
  ACCOUNTANTS: "Accountants",
  CUSTOMERS: "Customers",
  ASSESSMENT_STATUS: "Assessments Status",
  REVIEWS: "Reviews",
  REPORTS: "Reports",
  CLIENTS: "Clients",
  INSIGHTS_AND_DUIDES: "Insights & Guides",
  IMPORTANT_DATES: "Important Dates",
  TAX_FILLING: "Tax Filings",
  CHAT_HISTORY: "Chat History",
  RECENT_REVIEWS: "Recent Reviews",
  OTHER_REVIEWS: "Other Reviews",
  SERVICE_REQUESTS: "Service Requests",
  ACCEPT: "Accept",
  REJECT: "Reject",
  ACCOUNTANT_PROGRESS: "Accountant Progress",
  VERIFY_OTP: "Verify otp",
  RESEND_OTP: "Resend OTP",
  VERIFY_EMAIL: "Verify Email",
  RESET_PASSWORD: "Reset Password",
  ADMIN_SIGNIN: "Admin Sign in",
  AUTH_TOKEN: "authToken",
  ADD_BUSINESS: "Add Business",
  ADD_ACCOUNTANT: "Add Accountant",
  ACCOUNTANT_SIGNIN: "Accountant Sign in",
  ACCOUNTANT_PASSWORD_RESET: "Accountant Password Reset",
  IN_REVIEW: "In Review",
  COMPLETED: "Completed",
  CANCELLED: "Cancelled",
  HMRC_IMPORTANT_DATES: "HMRC Important Dates",
  EMPLOYMENT: "Employment",
  NOT_STARTED: "Not Started",
  COMPLETED_STATUS: "COMPLETED",
  IN_REVIEW_STATUS: "IN_REVIEW",
  ALL: "All",
  UK_PROPERTY: "UK property",
});
export const ROUTES = Object.freeze({
  // Customer Routes

  DASHBOARD: "/dashboard",
  LOGIN: "/login",
  SIGNUP: "/signup",
  ASSESSMENT: "/assessment",
  OVERVIEW: "/overview",
  PROFILE: "/profile",
  SETTINGS: "/settings",
  CUSTOMERP_ROFILE: "/customerprofile",
  PERSONAL_TAX: "/personaltax",
  PARTNERSHIP_TAX: "/partnershiptax",
  INSIGHTS_GUIDES: "/insights-guides",
  LIMITED_COMPANY: "/limited-company",
  BUSINESSES: "/businesses",
  ACCOUNTANTS: "/accountants",
  CUSTOMERS: "/customers",
  VERIFY_OTP: "/verify-otp",
  FORGOT_PASSWORD: "/forgot-password",
  LANDING: "/get-started",
  INTERMEDIATE_TAX_PAGE: "/intermediate-tax-page",
  PRIVACY_POLICY: "/privacy-policy",
  COMING_SOON: "/coming-soon",

  // Admin Routes
  ADMIN_LOGIN: "/admin/login",
  ADMIN_BUSINESSES: "/admin/businesses",
  ADMIN_ACCOUNTANTS: "/admin/accountants",
  ADMIN_CLIENTS: "/admin/clients",
  ADMIN_OVERVIEW: "/admin/overview",
  ADMIN_SETTINGS: "/admin/settings",
  ADMIN_ASSESSMENT_STATUS: "/admin/assessment-status",
  ADMIN_PROFILE: "/admin/profile",
  ADMIN_INDIVIDUALS: "/admin/clients:individuals",
  ADMIN_COMPANY: "/admin/clients:company",
  ADMIN_PARTERSHIPS: "/admin/clients:partnerships",
  ASMIN_INSIGHTS_AND_DUIDES: "/admin/insights-guides",
  ADMIN_CUSTOMERS: "/admin/customers",
  ADMIN_ADD_BUSINESS: "/admin/add-business",
  ADMIN_ADD_ACCOUNTANTS: "/admin/add-accountants",

  // Accountant Routes
  ACCOUNTANT_LOGIN: "/accountant/login",
  ACCOUNTANT_PASSWORD_RESET: "/accountant/password-reset",
  ACCOUNTANT_OVERVIEW: "/accountant/overview",
  ACCOUNTANT_TAX_FILLING: "/accountant/taxfilling",
  ACCOUNTANT_CHAT_HISTORY: "/accountanta/chat-history",
  ACCOUNTANTA_INSIGHTS_GUIDES: "/accountanta/insights-guides",
  ACCOUNTANTA_SETTINGS: "/accountanta/setting",
  HMRC_DATE_PAGE: "/hmrc/dates",
  ACCOUNTANT_REVIEWS: "/accountant/reviews",
  ACCOUNTANT_SERVICE_REQUESTS: "/accountant/service-requests",
});
export const ERROR_MESSAGE = Object.freeze({
  INVALID_EMAIL: "Invalid email",
  INVALID_PASSWORD: "Password cannot be empty",
  MISMATCHED_PASSWORD: "Passwords did not match",
  PASSWORD_REGEX:
    "Password must be at least 8 characters long and contain at least one uppercase letter, one lowercase letter, one digit, and one special character (e.g., !@#$%^&*).",
  INVALID_UPPERCASE: "Password should include at least one uppercase letter",
  INVALID_LOWERCASE: "Password should include at least one lowercase letter",
  INVALID_DIGIT: "Password should include at least one digit",
  INVALID_SPECIALCHAR: "Password should include at least one special character",
});
export const USER_TYPE = Object.freeze({
  CUSTOMER: "customer",
  ADMIN: "admin",
  ACCOUNTANT: "accountant",
  BUSINESS: "business",
  SERVICE_MANAGER: "service manager",
});

export const REGEX = Object.freeze({
  PASS_REGEX: /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.* ).{8,16}$/,
  EMAIL_REGEX: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/,
  UPPERCASEREGEX: /^(?=.*?[A-Z])/,
  LOWERCASEREGEX: /^(?=.*?[a-z])/,
  DIGITREGEX: /^(?=.*?[0-9])/,
  SPECIALCHARREGEX: /^(?=.*?[#?!@$%^&*-])/,
});

export const MODAL_MSGS = Object.freeze({
  DELETE_EMPLOYMENT_TITLE: "Are you sure you want to delete this employment?",
  LOGOUT_TITLE: "Are you sure you want to Logout?",
  MODAL_YES: "Yes",
  MODAL_NO: "No",
});

export const LandingScreenConstants = {
  PAGE_TITLE: "We Make",
  PAGE_TITLE_PART: "Progress",
  PAGE_SUBTITLE: "On Your Business",
  PAGE_DESCRIPTION:
    "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
  BUTTON_GET_STARTED: "Get Started",
  BUTTON_LEARN_MORE: "Learn More",
  NAV_SERVICES: "Services",
  NAV_INSIGHTS: "Insights",
  NAV_PRICING: "Pricing",
  NAV_CONTACT_US: "Contact us",
  LOGO_TEXT: "# TIM",
  SIGN_IN: "Sign In",
  SIGN_UP: "Sign Up",
};
