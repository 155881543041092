// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* styles.css */

.textInput {
  font-family: 'Poppins', sans-serif;
  border: 1px solid #C1C1C1; /* Example border color */
  padding: 12px;
  border-radius: 8px;
  font-size: 16px;
}

.textInputLabel {
  font-family: 'Poppins', sans-serif;
  font-weight: 500; /* Medium weight */
  font-size: 14px;
  margin-bottom: 8px;
}

.textInputDescription {
  font-family: 'Poppins', sans-serif;
  font-weight: 400; /* Regular weight */
  font-size: 12px;
  margin-top: 4px;
}

.textInputError {
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 12px;
  color: red;
  margin-top: 4px;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA,eAAe;;AAEf;EACE,kCAAkC;EAClC,yBAAyB,EAAE,yBAAyB;EACpD,aAAa;EACb,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,kCAAkC;EAClC,gBAAgB,EAAE,kBAAkB;EACpC,eAAe;EACf,kBAAkB;AACpB;;AAEA;EACE,kCAAkC;EAClC,gBAAgB,EAAE,mBAAmB;EACrC,eAAe;EACf,eAAe;AACjB;;AAEA;EACE,kCAAkC;EAClC,gBAAgB;EAChB,eAAe;EACf,UAAU;EACV,eAAe;AACjB","sourcesContent":["/* styles.css */\n\n.textInput {\n  font-family: 'Poppins', sans-serif;\n  border: 1px solid #C1C1C1; /* Example border color */\n  padding: 12px;\n  border-radius: 8px;\n  font-size: 16px;\n}\n\n.textInputLabel {\n  font-family: 'Poppins', sans-serif;\n  font-weight: 500; /* Medium weight */\n  font-size: 14px;\n  margin-bottom: 8px;\n}\n\n.textInputDescription {\n  font-family: 'Poppins', sans-serif;\n  font-weight: 400; /* Regular weight */\n  font-size: 12px;\n  margin-top: 4px;\n}\n\n.textInputError {\n  font-family: 'Poppins', sans-serif;\n  font-weight: 400;\n  font-size: 12px;\n  color: red;\n  margin-top: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
